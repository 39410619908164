<template>
  <div class="form">
    <div class="row">
      <div class="column">
        <Input
          type="text"
          title="Titre"
          name="program-name"
          v-model="editableProgram.name"
        />

        <Textarea
          type="text"
          title="Message de lancement"
          name="program-message"
          v-model="editableProgram.message"
        />
      </div>
      <div class="column">
        <MultipleSelect
          :options="functionalCapacities"
          title="Capacités fonctionnelles"
          name="program-capacities"
          :value="[...editableProgram.functionalCapacity]"
          v-model="editableProgram.functionalCapacity"
        />

        <MultipleSelect
          title="Pathologies / Symptômes"
          name="program-pathologies"
          :options="pathologies"
          :value="[...editableProgram.pathologies]"
          v-model="editableProgram.pathologies"
        />
      </div>
    </div>

    <div class="row">
      <div class="column">
        <h2 class="title-2">Exercices (Temps total :  {{ programDuration }}) </h2>

        <div class="exercises-list">
          <div
            v-for="exercise in editableProgram.exercises"
            :key="exercise.id"
            class="exercise"
          >
            <div class="exercise-thumbnail">
              <Thumbnail :title="exercise.name" :imageUrl="exercise.thumbnailUrl" />
            </div>
            <div class="exercise-content">
              <Range
                title="Durée d'une série"
                name="program-defaultDuration"
                unit="s"
                :min="30"
                :max="600"
                :step="15"
                v-model="exercise.duration"
                :initialValue="exercise.duration"
              />
              <Range
                title="Nombre de séries"
                name="exercice-defaultCount"
                :min="1"
                :max="10"
                :step="1"
                v-model="exercise.count"
                :initialValue="exercise.count"
              />
              <Range
                title="Temps de récupération entre séries"
                name="exercice-defaultRestTime"
                unit="s"
                :min="10"
                :max="300"
                :step="10"
                v-model="exercise.restTime"
                :initialValue="exercise.restTime"
              />
            </div>
            <div class="exercise-buttons">
              <Button
                title="Retirer du programme"
                type="warning"
                @click="removeExercise(exercise)"
              />
            </div>
          </div>
        </div>

        <div class="add-exercise-button">
          <Button
            title="Ajouter un exercice"
            type="secondary"
            @click="openExercisesModal"
          >
            <SVGPlus />
          </Button>
        </div>
      </div>
    </div>

    <Modal
      class="modal"
      :addScroll="true"
      v-if="displayExercisesModal"
      :close="closeExercisesModal"
    >
      <h2 class="title-2">Choisir un exercice</h2>

      <div class="grid">
        <div
          v-for="(exercise, i) in exercises"
          :key="i"
          class="exercise column"
          @click="addExercise(exercise)"
        >
          <Thumbnail
            class="thumbnail"
            :title="exercise.name"
            :imageUrl="exercise.thumbnailUrl"
          />
        </div>
      </div>
    </Modal>

    <BottomBar v-if="mode === 'edit'">
      <div class="message">
        <p v-if="hasProgramChanged">Modifications non sauvegardées</p>
      </div>

      <Button title="Sauvegarder" 
        type="primary" 
        @click="submit" 
        :disabled="!isReadyToSave" />
      <Button title="Supprimer" type="warning" @click="showRemoveProgramPopup" />
    </BottomBar>

    <BottomBar v-if="mode === 'add'">
      <div class="message">
        <p v-if="hasProgramChanged">Modifications non sauvegardées</p>
      </div>

      <Button title="Sauvegarder" 
          type="primary" 
          @click="submit"
          :disabled="!isReadyToSave" /> 
          {{ isReadyToSave }}
    </BottomBar>
    <ConfirmationModal
      v-if="showRemoveModal"
      @close="showRemoveModal = false"
      @confirm="removeProgram"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import BottomBar from "@/components/Layout/BottomBar";
import Input from "@/components/Forms/Fields/Input";
import Textarea from "@/components/Forms/Fields/Textarea";
import MultipleSelect from "@/components/Forms/Fields/MultipleSelect";
import Range from "@/components/Forms/Fields/Range";
import Thumbnail from "@/components/Thumbnail";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import SVGPlus from "@/assets/icons/plus.svg";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal.vue"
import { formatTime } from "../../utils/formatter";

export default {
  name: "ProgramForm",
  components: {
    BottomBar,
    Input,
    Textarea,
    MultipleSelect,
    Range,
    Thumbnail,
    Button,
    Modal,
    SVGPlus,
    ConfirmationModal
  },
  props: {
    program: {
      default() {
        return {
          capacities: [],
          pathologies: [],
        };
      },
    },
    mode: {
      type: String,
      default: "edit",
      validator: function (value) {
        return ["edit", "add"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      originalProgram: {},
      editableProgram: {},
      displayExercisesModal: false,
      isSubmitting: false,
      fcCapacities: [],
      showRemoveModal: false
    };
  },
  async created() {
    this.originalProgram = _.cloneDeep(this.program);
    this.editableProgram = _.cloneDeep(this.program);
    if (this.editableProgram?.functionalCapacity?.length) {
      this.editableProgram.functionalCapacity = this.editableProgram?.functionalCapacity.map(
        (el) => el.index
      );
    }
    this.editableProgram.exercises = this.editableProgram.exercises || [];
    this.editableProgram.pathologies = this.editableProgram.pathologies.map(
      (el) => el.id
    );
    await this.$store.dispatch("admin/getPathologies");
    await this.$store.dispatch("admin/getExercises");
    this.fcCapacities = await this.$store.dispatch("admin/getFunctionalCapacities");
  },
  methods: {
    formatOptions(options) {
      return Object.entries(options).map((entry) => ({
        value: parseInt(entry[0]),
        name: entry[1],
      }));
    },
    showRemoveProgramPopup() {
      this.showRemoveModal = true
    },
    removeProgram() {
      this.$emit("removeProgram", this.originalProgram.id)
      this.showRemoveModal = false
    },
    openExercisesModal() {
      this.displayExercisesModal = true;
    },
    closeExercisesModal() {
      this.displayExercisesModal = false;
    },
    addExercise(exercise) {
      const newExercise = _.cloneDeep(exercise);

      newExercise.duration = exercise.defaultDuration;
      newExercise.restTime = exercise.defaultRestTime;
      newExercise.count = exercise.defaultCount;
      let arr = [];
      if (this.editableProgram?.exercises?.length) {
        arr = this.editableProgram.exercises;
      }
      arr.push(newExercise);
      this.editableProgram.exercises = [...arr];

      this.closeExercisesModal();
    },
    removeExercise(exercise) {
      let arr = [...this.editableProgram.exercises];
      arr.splice(arr.indexOf(exercise), 1);
      this.editableProgram.exercises = [...arr];
      this.$forceUpdate();
    },
    async submit() {
      let program = {
        name: this.editableProgram?.name,
        message: this.editableProgram?.message? this.editableProgram?.message  : ' ',
        functionalCapacities: this.fcCapacities.filter((el) =>
          this.editableProgram?.functionalCapacity.includes(el.index)
        ),
        pathologies:
          this.editableProgram?.pathologies &&
          this.editableProgram?.pathologies?.map((el) => {
            return { id: el };
          }),
        exercises: this.editableProgram.exercises.map(
          ({ id, duration, restTime, count },i) => ({
            exerciseId: id,
            order : i,
            duration,
            restTime,
            count         
          })
        ),
      };
      if (this.mode === "add") {
        program.pathologies = program.pathologies.map((el) => el.id);
        this.isSubmitting = true;
        try {
          await this.$store.dispatch("admin/addProgram", { program: program });
          this.$router.push({ name: "ProgramsList" });
        } catch (e) {
          console.error(e);
        }

        this.isSubmitting = false;
      }
      if (this.mode === "edit") {
        this.isSubmitting = true;
        try {
          await this.$store.dispatch("admin/editProgram", {
            program: program,
            programId: this.program.id,
          });
          this.$router.push({ name: "ProgramsList" });
        } catch (e) {
          console.error(e);
        }
        this.isSubmitting = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      exercises: "admin/exercises",
      pathologiesList: "admin/pathologies",
    }),
    functionalCapacities() {
      return _.tail(this.formatOptions(this.$t("functionalCapacity")));
    },
    pathologies() {
      return this.pathologiesList.map(({ id, name }) => ({ value: id, name }));
    },
    hasProgramChanged() {
      return !_.isEqual(this.originalProgram, this.editableProgram);
    },
    isReadyToSave() {
      return (
        this.editableProgram.name &&
        this.editableProgram.message &&
        this.editableProgram.functionalCapacity &&
        this.editableProgram.pathologies &&
        this.editableProgram.exercises
      )
    },
    programDuration() {
      return formatTime(
        this.editableProgram.exercises.reduce(
          (lastDuration, nextExercise) =>
          lastDuration +
          nextExercise.duration * nextExercise.count +
          nextExercise.restTime * (nextExercise.count - 1),
        0
      ))
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 10px 0;
  margin-top: 20px;

  .row {
    > div {
      flex: 1;
    }
  }

  .exercises-list {
    display: flex;
    flex-direction: column;

    .exercise {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $light-grey-color;
      margin-bottom: 20px;

      .exercise-thumbnail {
        width: 200px;
      }

      .exercise-content {
        flex-grow: 1;
      }

      .exercise-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;

        > div,
        > a {
          margin: 0;
          margin-bottom: 10px;
        }
      }
    }
  }

  .add-exercise-button {
    display: flex;
    justify-content: center;

    > div {
      flex-grow: 0;
    }
  }

  .buttons-container {
    justify-content: center;
    margin: 20px 0;

    div {
      flex: 0;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 10px;

    .exercise {
      min-width: 200px;
      margin-bottom: 10px;
    }
  }

  .message {
    flex-grow: 1;
  }
}
</style>
