
module.exports.numberWithSpaces = (x) => {
  if (!x) {
    return '0'
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

module.exports.formatDistance = (value) => {
  if (value !== null) {
    return this.numberWithSpaces(Math.round(value / 1000)) + ' m'
  }
}
module.exports.formatTime = (time) => {
  class CustomTime {
    time = ''
    unit = ''
    self = undefined
    constructor(value, self) {
      this.self = self
      if (!value && value !== 0) {
        return
      }
      if (value >= 3600) {
        this.time = Math.floor((value / 3600))
        let minutes = Math.floor(((value - this.time * 3600) / 60))
          minutes = '0' + minutes
          this.unit = ' h' + minutes.slice(-2)
      } else if (value >= 60) {
        this.time = Math.round(value / 60)
        let seconds = Math.floor(((value - this.time * 60)))
        this.time += ' min'
        if(seconds > 0){
          this.time += ' ' + seconds + ' sec'
        }
      } else if (value > 0) {
        this.time = value
        this.unit = ' sec'
      } else {
        this.time = '0'
        this.unit = ""
      }
      this.time = this.time.toString().replace('.', ',')
    }

    toString() {
      if (!this.unit) return this.time
      return `${this.self.numberWithSpaces(this.time)}${this.unit}`
    }
  }
  return new CustomTime(time, this)
}

module.exports.format = (value, type) => {
  if (type === 'time') {
    return this.formatTime(value)
  }
  if (type === 'distance') {
    return this.formatDistance(value)
  }
  if (type === '%') return this.numberWithSpaces(value) + '%'

  return this.numberWithSpaces(value)
}
